import { Form, Formik } from 'formik'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import { useGetCountriesQuery } from 'api/countriesApi'
import { useLoginMutation, useRegisterUserMutation, useUpgradeGuestMutation } from 'api/usersApi'
import { IError } from 'errors/types/IError'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import AuthFormField, { FieldType } from 'login/components/AuthFormField'
import {
  LoginOrCreateAccountLocation,
  logLoginOrCreateAccountClick,
  logUserLoginOrCreation,
  mParticleIdentifyUser,
} from 'mParticle/mParticleService'
import { Trans, useTranslation } from 'react-i18next'
import { emailValidation, fullNameValidation } from 'shared/addressValidations'
import { Button } from 'shared/components/Button'
import { FormikCheckbox } from 'shared/components/Checkbox'
import { FCLink } from 'shared/components/Links'
import LinkCTA from 'shared/components/Links/LinkCTA'
import Spinner from 'shared/components/Spinner'
import { CheckoutQA, UserAuthQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { STORAGE } from 'shared/enums/SitePreferences'
import { colors, fonts } from 'shared/lib'
import { trackImpactIdentify } from 'tracking/impactService'
import { trackSnapchatSignUp } from 'tracking/snapchatService'

const TP = 'login.components.CreateAccountForm'

type CreateAccountFormProps = {
  buttonText?: string
  emailDisabled?: boolean
  guestCheckoutEmail?: string
  guestFullName?: string
  guestOrderNumber?: number
  isDarkMode?: boolean
  isGuestUpgrade?: boolean
  /** location where the user creates the account from. Used for tracking. */
  location: LoginOrCreateAccountLocation
  loginInsteadCta?: string
  loginInsteadDescription?: string
  loginInsteadLink?: string
  loginInsteadStyle?: 'link' | 'modal' | 'button' | 'buttonAndDescription' | 'linkAndDescription'
  loginInsteadText?: string
  onCreateAccount(): void
  onCreateAccountError(errors: IError[]): void
  onLoginClick?: () => void
}

type CreateAccountFormFields = {
  confirmPassword: string
  email: string
  fullName: string
  isNewsletterActive: boolean
  orderNumber?: number // hidden field used for validating user for guest account upgrade
  password: string
}

const CreateAccountForm = ({
  buttonText = t(`${TP}.createAxccount`, 'Create Account'),
  emailDisabled = false,
  guestCheckoutEmail = '',
  guestFullName,
  guestOrderNumber,
  isDarkMode = false,
  isGuestUpgrade = false,
  location,
  loginInsteadCta = t(`${TP}.logIn`, 'Log in'),
  loginInsteadDescription = '',
  loginInsteadLink = '/customer/account/login',
  loginInsteadStyle = 'button',
  loginInsteadText = t(`${TP}.loginInsteadText`, 'Already have an account?'),
  onCreateAccount,
  onCreateAccountError,
  onLoginClick = () => {},
}: CreateAccountFormProps) => {
  const { data: countriesData, isLoading: isLoadingGetCountries } = useGetCountriesQuery()

  const [
    registerUser,
    {
      data: registerUserData,
      error: registerUserError,
      isLoading: isLoadingRegisterUser,
      isSuccess: isSuccessRegisterUser,
      isError: isErrorRegisterUser,
    },
  ] = useRegisterUserMutation()
  const [
    upgradeGuest,
    {
      data: upgradeGuestData,
      error: upgradeGuestError,
      isLoading: isLoadingUpgradeGuest,
      isSuccess: isSuccessUpgradeGuest,
      isError: isErrorUpgradeGuest,
    },
  ] = useUpgradeGuestMutation()
  const [
    login,
    {
      error: loginError,
      isError: isLoginError,
      isLoading: isLoadingLogin,
      isSuccess: isSuccessLogin,
    },
  ] = useLoginMutation()
  const isEmailSignupPrecheckEnabled = useFeatureFlag(
    FeatureFlag.WEB_FC_ENABLE_PRECHECK_EMAIL_SIGNUP,
  )

  const tv = useTranslation()
  const availableCountries = countriesData?.countries.filter((country) => country.shipsTo)
  const isGuestCheckout = !!guestCheckoutEmail
  const precheckEmailSignup =
    isEmailSignupPrecheckEnabled &&
    !!availableCountries?.find((country) => country.isoCode === Cookies.get(STORAGE.COUNTRY))
      ?.allowPrecheckEmailSignup

  const initialCreateAccountFields: CreateAccountFormFields = {
    fullName: guestFullName || '',
    email: isGuestCheckout ? guestCheckoutEmail : '',
    password: '',
    confirmPassword: '',
    isNewsletterActive: precheckEmailSignup,
    ...(guestOrderNumber && { orderNumber: guestOrderNumber }), // hidden value for guest checkout
  }
  const CreateAccountSchema = Yup.object().shape({
    fullName: fullNameValidation(),
    email: emailValidation(),
    password: Yup.string()
      .min(1)
      .required(t(`${TP}.passwordRequired`, 'Password is a required field.')),
    ...(!isGuestCheckout && {
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref('password')],
          t(`${TP}.passwordNotMatched`, 'Confirm password does not match.'),
        )
        .required(t(`${TP}.confirmPasswordRequired`, 'Confirm password is a required field.')),
    }),
    isNewsletterActive: Yup.boolean(),
  })

  const handleCreate = async (formValues: CreateAccountFormFields) => {
    const { confirmPassword, email, fullName, isNewsletterActive, orderNumber, password } =
      formValues
    const body = {
      email,
      name: fullName,
      password,
      ...(confirmPassword && { confirmPassword }),
      ...(orderNumber && { orderNumber }),
    }

    logLoginOrCreateAccountClick({
      eventType: 'account_creation',
      newsletterSignUp: isNewsletterActive,
      email,
      location,
    })

    if (isGuestCheckout || isGuestUpgrade) {
      await upgradeGuest(body).unwrap()
    } else {
      await registerUser(body).unwrap()
    }
  }

  const handleCreateComplete = () => {
    trackSnapchatSignUp({ success: 1 })
    const user = isGuestCheckout || isGuestUpgrade ? upgradeGuestData : registerUserData
    trackImpactIdentify(user)
    mParticleIdentifyUser(user, 'register', 0, () => {
      logUserLoginOrCreation({
        eventType: 'user_created',
        email: user?.email,
        isGuest: user?.isGuest,
      })
    })
    onCreateAccount()
  }

  const handleCreateAccountError = () => {
    trackSnapchatSignUp({ success: 0 })
    const error = isGuestCheckout || isGuestUpgrade ? upgradeGuestError : registerUserError
    onCreateAccountError(error?.data?.localized_messages || error?.data?.messages)
  }

  useEffect(() => {
    if (isErrorRegisterUser || isErrorUpgradeGuest) {
      handleCreateAccountError()
    }

    if (isLoginError) {
      onCreateAccountError(loginError?.data?.localized_messages || loginError?.data?.messages)
    }
  }, [isErrorRegisterUser, isErrorUpgradeGuest, isLoginError])

  useEffect(() => {
    if (isSuccessRegisterUser || isSuccessUpgradeGuest || isSuccessLogin) {
      handleCreateComplete()
    }
  }, [isSuccessRegisterUser, isSuccessUpgradeGuest, isSuccessLogin])

  return (
    <Spinner
      showSpinner={
        isLoadingGetCountries || isLoadingRegisterUser || isLoadingUpgradeGuest || isLoadingLogin
      }
      text={
        isLoadingRegisterUser || isLoadingUpgradeGuest
          ? t(`${TP}.spinnerText`, 'Creating account...')
          : ''
      }
      subText={t(`${TP}.spinnerSubText`, 'Please do not close browser!')}
    >
      {/*
            enableReinitialize is necessary for the forced rerender,
            see ManageOrderCreateAccount
          */}
      <Formik
        enableReinitialize
        initialValues={initialCreateAccountFields}
        onSubmit={handleCreate}
        validationSchema={CreateAccountSchema}
      >
        {({ dirty, isValid, values }) => (
          <Form>
            <AuthFormField
              title={t(`${TP}.fullName`, 'Full Name')}
              name="fullName"
              type={FieldType.FullName}
              qaAttr={UserAuthQA.CreateAccountNameBox}
              isDarkMode={isDarkMode}
            />
            <AuthFormField
              title={t(`${TP}.emailAddress`, 'Email Address')}
              name="email"
              type={FieldType.Email}
              qaAttr={UserAuthQA.CreateAccountEmailBox}
              isDarkMode={isDarkMode}
              disabled={isGuestCheckout || emailDisabled}
            />
            <AuthFormField
              title={t(`${TP}.password`, 'Password')}
              name="password"
              type={FieldType.NewPassword}
              qaAttr={UserAuthQA.CreateAccountNewPassBox}
              isDarkMode={isDarkMode}
            />
            {!isGuestCheckout && (
              <AuthFormField
                title={t(`${TP}.confirmPassword`, 'Confirm Password')}
                name="confirmPassword"
                type={FieldType.ConfirmPassword}
                qaAttr={UserAuthQA.CreateAccountConfirmPassBox}
                isDarkMode={isDarkMode}
              />
            )}
            <EmailSignUp
              name="isNewsletterActive"
              label="newsletter-checkbox"
              text={t(
                `${TP}.emailSignUp`,
                "Sign up to receive Flight Club's email newsletter with special promotions, news and more.",
              )}
              checked={values?.isNewsletterActive}
              isDarkMode={isDarkMode}
              noMargin
            />
            <TermsAndConditions>
              <Trans
                i18nKey={`${TP}.TermsAndPrivacyText`}
                t={tv.t}
                defaults="By creating an account, you agree to the <0>Privacy Policy</0> and <1>Terms of Use</1>"
                components={[
                  <FCLink qaAttr="CreateAccountPrivacyLink" href="/privacy" newTab />,
                  <FCLink qaAttr="CreateAccountTermsLink" href="/terms-conditions" newTab />,
                ]}
              />
            </TermsAndConditions>
            <CreateAccountActions>
              <StyledButton
                $fill
                buttonType="primary2"
                type="submit"
                disabled={!(isValid && dirty) || isLoadingRegisterUser || isLoadingUpgradeGuest}
                qaAttr={UserAuthQA.PrimaryCreateAccountButton}
                data-testid="create-account-button"
                isDarkMode={isDarkMode}
              >
                {buttonText}
              </StyledButton>
              {loginInsteadStyle === 'link' && (
                <StyledLinkCTA qaAttr={CheckoutQA.AlreadyHaveAnAccountLink} href={loginInsteadLink}>
                  {loginInsteadText}
                </StyledLinkCTA>
              )}
              {loginInsteadStyle === 'linkAndDescription' && (
                <>
                  <Divider />
                  <LinkAndDescription>
                    <LinkDescription qaAttr="NewToFlightClubText">
                      {loginInsteadText}
                    </LinkDescription>
                    <LinkButton
                      $fill
                      buttonType="link"
                      component="button"
                      onClick={onLoginClick}
                      qaAttr={UserAuthQA.SecondaryLoginButton}
                    >
                      {loginInsteadCta}
                    </LinkButton>
                  </LinkAndDescription>
                </>
              )}
              {loginInsteadStyle === 'modal' && (
                <LoginActions>
                  <Divider />
                  <ModalLoginText isDarkMode={isDarkMode}>{loginInsteadText}</ModalLoginText>
                  <Button
                    $fill
                    buttonType="secondary"
                    component="button"
                    onClick={onLoginClick}
                    qaAttr={UserAuthQA.SecondaryLoginButton}
                  >
                    {loginInsteadCta}
                  </Button>
                </LoginActions>
              )}
              {loginInsteadStyle === 'button' && (
                <LoginActions>
                  <Divider />
                  <Text data-qa={UserAuthQA.AlreadyHaveAnAccountText}>{loginInsteadText}</Text>
                  <StyledButton
                    $fill
                    buttonType="secondary"
                    component="button"
                    onClick={onLoginClick}
                    qaAttr={UserAuthQA.SecondaryLoginButton}
                  >
                    {loginInsteadCta}
                  </StyledButton>
                </LoginActions>
              )}
              {loginInsteadStyle === 'buttonAndDescription' && (
                <LoginActions>
                  <Divider />
                  <LoginInsteadHeader qaAttr={UserAuthQA.AlreadyHaveAnAccountText}>
                    {t(`${TP}.haveAnAccount`, 'Have an account?')}
                  </LoginInsteadHeader>
                  <LoginInsteadDescription>{loginInsteadDescription}</LoginInsteadDescription>
                  <Button
                    $fill
                    buttonType="secondary"
                    component="button"
                    onClick={onLoginClick}
                    qaAttr={UserAuthQA.SecondaryLoginButton}
                  >
                    {loginInsteadCta}
                  </Button>
                </LoginActions>
              )}
            </CreateAccountActions>
          </Form>
        )}
      </Formik>
    </Spinner>
  )
}

const CreateAccountActions = styled.div`
  display: flex;
  flex-direction: column;
`
const LoginActions = styled.div`
  display: flex;
  flex-direction: column;
`
const EmailSignUp = styled(FormikCheckbox)`
  margin: 10px 0 10px;
`
const Divider = styled.div`
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
  margin: 25px 0;
`
const ModalLoginText = styled.p<{ isDarkMode: boolean }>`
  ${fonts.SUBTITLE_3}
  margin: 0 0 20px;
  color: ${({ isDarkMode }) => (isDarkMode ? colors.FC2_WHITE : colors.FC2_GREY)};
`
const StyledLinkCTA = styled(LinkCTA)`
  margin-top: 20px;
`
const LinkAndDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const LinkButton = styled(Button)`
  color: ${colors.FC2_BLACK};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    color: ${colors.FC2_DARK_GREY};
  }
`
const LinkDescription = styled.span`
  ${fonts.SUBTITLE_2}
  color: ${colors.FC2_DARK_GREY};
`
const LoginInsteadHeader = styled.h3`
  ${fonts.SUBTITLE_2};
  margin: 0;
`
const LoginInsteadDescription = styled.p`
  ${fonts.BODY_TEXT};
  margin: 10px 0 30px;
`
const TermsAndConditions = styled.div`
  ${fonts.SUBTITLE_3};
  color: ${colors.FC2_GREY};
  font-weight: 400;
  margin: 10px 0 20px;
`

const Text = styled.div`
  color: ${colors.FC2_BLACK};
  margin-bottom: 16px;
  font-weight: 500;
  line-height: normal;
  font-size: 12px;
`

const StyledButton = styled(Button)`
  line-height: normal;
`
export default CreateAccountForm
