import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import styled, { ThemeProvider, css } from 'styled-components'

import { FavoritesNavLink } from 'favorites/FavoritesNavLink'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import SearchMenu, {
  SearchMenu as ConsumerSearchSearchMenu,
} from 'layout/navigation/search/SearchMenu'
import { t } from 'localization'
import { headerNavigationTrackEvent } from 'mParticle/headerNavigationTrackEvent'
import { AlgoliaIndex } from 'search'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useDebouncedValue } from 'shared/hooks/useDebouncedValue'
import { useWindowSize } from 'shared/hooks/useWindowSize'
import FlightClubLogoSVG, { FlightClubLogoIcon } from 'shared/icons/FlightClubLogo'
import { media } from 'shared/lib'
import { sizes } from 'shared/lib/media'
import { BodyText } from 'shared/text'
import { mediaQueries } from 'shared/theme'
import colors, { ThemeColor } from 'shared/theme/colors'
import AccountMenu from './AccountMenu'
import MobileMenu from './MobileMenu'
import { SneakersMenu } from './SneakersMenu'
import HoverIntent from './components/HoverIntent'
import NavLink from './components/NavLink'
import Search from './search/Search'

const TP = 'layout.navigation.search.navigation'
// NAVIGATION_THEMES
// Themes allow the navigation to respond to page scroll, routing, and menu events
// by changing and animating its style properties. All sub-components - no matter
// how deeply nested - have access to theme colors and properties.
export type NavThemeNames = 'default' | 'overlayed' | 'search' | 'sneakers' | 'checkout' | 'mobile'
export interface NavigationTheme {
  backgroundColor: ThemeColor
  borderColor: ThemeColor
  textColor: ThemeColor
  searchBorderColor: ThemeColor
  hideLogo?: boolean
  hideLinks?: boolean
  hideSearch?: boolean
  menuOpen?: boolean
}
type NavigationThemes = {
  [theme in NavThemeNames]: NavigationTheme
}
const NAVIGATION_THEMES: NavigationThemes = {
  default: {
    backgroundColor: colors.white,
    borderColor: colors.transparent,
    searchBorderColor: colors.offWhite,
    textColor: colors.black,
  },
  overlayed: {
    backgroundColor: colors.transparent,
    borderColor: colors.transparent,
    searchBorderColor: colors.glass,
    textColor: colors.white,
  },
  search: {
    // for search and search results
    backgroundColor: colors.white,
    borderColor: colors.offWhite,
    hideLinks: true,
    hideLogo: true,
    menuOpen: true,
    searchBorderColor: colors.transparent,
    textColor: colors.black,
  },
  sneakers: {
    // for sneakers menu
    backgroundColor: colors.white,
    borderColor: colors.offWhite,
    hideLinks: false,
    hideLogo: false,
    menuOpen: false,
    searchBorderColor: colors.transparent,
    textColor: colors.black,
  },
  checkout: {
    backgroundColor: colors.white,
    borderColor: colors.transparent,
    hideLinks: true,
    hideSearch: true,
    searchBorderColor: colors.offWhite,
    textColor: colors.black,
  },
  mobile: {
    backgroundColor: colors.white,
    borderColor: colors.offWhite,
    hideLinks: false,
    hideSearch: true,
    textColor: colors.black,
    searchBorderColor: colors.transparent,
    menuOpen: true,
  },
}

const RawNavigation = ({
  navTheme,
  setNavTheme,
}: {
  navTheme: NavThemeNames
  setNavTheme: any
}) => {
  const isConsumerSearchEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_CONSUMER_SEARCH)

  const { asPath: pathWithQueries, events: routerEvents } = useRouter()
  const displayFlightClubFridays = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_FLIGHT_CLUB_FRIDAYS)
  const isEditorialsEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_EDITORIALS)
  const isFavoritesEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_FAVORITES)

  // query strings and hashes need to be stripped out
  const asPath = pathWithQueries.split(/[?#]/)[0]

  const [isOverlayed, setIsOverlayed] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebouncedValue(searchText, 650)
  // this is a reference to the timeout that triggers the closing of the sneakers menu, it can
  // be cancelled when switching to search to prevent nav from resetting for a smoother transition
  const [sneakersCancellableHoverTimeout, setSneakersCancellableHoverTimeout] = useState<number>()
  const intersectionObserver = useRef<IntersectionObserver>()
  const { isMobile } = useWindowSize()

  const getUnopenedNavTheme = (): NavThemeNames => {
    if (asPath.startsWith('/checkout')) {
      return 'checkout'
    }
    if (isOverlayed) {
      return 'overlayed'
    }
    return 'default'
  }
  const closeNav = () => setNavTheme(getUnopenedNavTheme())

  useEffect(() => {
    routerEvents.on('routeChangeComplete', closeNav)
  }, [])

  useEffect(() => {
    if (navTheme !== 'search' && navTheme !== 'sneakers' && navTheme !== 'mobile') {
      closeNav()
    }
  }, [isOverlayed, navTheme])

  // route transition effects
  // anything that needs to run on every page happens here
  useEffect(() => {
    // init the intersectionObserver if it doesn't exist
    if (!intersectionObserver.current) {
      intersectionObserver.current = new IntersectionObserver(
        (intersections: IntersectionObserverEntry[]) => {
          intersections.forEach((intersection) => {
            if (intersection.target.classList.contains('transparent-nav-overlay')) {
              setIsOverlayed(intersection.isIntersecting)
            }
          })
        },
      )
    }
    // disconnect and reconnect observation on route change to catch
    // new .transparent-nav-overlay elements
    intersectionObserver.current.disconnect()
    setTimeout(() => {
      const overlays = document.getElementsByClassName('transparent-nav-overlay')
      for (const overlay of overlays) {
        intersectionObserver.current?.observe(overlay)
      }
    }, 1000)

    // reset overlay status
    // page transitions can leave isOverlayed === true
    // when transitioning to a page without any overlay items
    setIsOverlayed(false)
  }, [asPath])

  return (
    <React.Fragment>
      <NavContainer role="navigation">
        <LeftContainer>
          {navTheme !== 'mobile' && (
            <Search
              debouncedSearch={debouncedSearch}
              getUnopenedNavTheme={getUnopenedNavTheme}
              navTheme={navTheme}
              searchText={searchText}
              setNavTheme={setNavTheme}
              setSearchText={setSearchText}
              sneakersCancellableHoverTimeout={sneakersCancellableHoverTimeout}
            />
          )}
          {isEditorialsEnabled && !isMobile && (
            <>
              <StyledLink
                $addTransition={true}
                href="/sneakers"
                onClick={(e) => headerNavigationTrackEvent(e)}
                onFocus={() => setNavTheme('sneakers')}
              >
                <HoverIntent onEnter={() => setNavTheme('sneakers')}>
                  <CustomNavLink
                    $isEditorialsEnabled={isEditorialsEnabled}
                    data-qa="NavigationSneakersLink"
                    tabIndex={-1}
                  >
                    {t(`${TP}.sneakers`, 'Sneakers')}
                  </CustomNavLink>
                </HoverIntent>
              </StyledLink>
              <StyledLink
                $addTransition={true}
                href="/apparel"
                onClick={(e) => headerNavigationTrackEvent(e)}
              >
                <CustomNavLink
                  $isEditorialsEnabled={isEditorialsEnabled}
                  data-qa="NavigationApparelLink"
                  disabled={asPath === '/apparel'}
                  tabIndex={-1}
                >
                  {t(`${TP}.fcApparel`, 'FC Apparel')}
                </CustomNavLink>
              </StyledLink>
            </>
          )}
          {/* Needs isMobile check to prevent double rendering of the component */}
          {isFavoritesEnabled && isMobile && (
            <FavoritesNavLink closeNav={closeNav} navTheme={navTheme} id="mobile" />
          )}
        </LeftContainer>
        <Link
          aria-label="FlightClub Home"
          href="/"
          onClick={(e) => headerNavigationTrackEvent(e)}
          tabIndex={-1}
        >
          <LogoContainer data-qa="navigation-link-home">
            {isEditorialsEnabled ? <FlightClubLogoIcon /> : <FlightClubLogo />}
          </LogoContainer>
        </Link>
        <LinkContainer>
          <DesktopButtons>
            {!isEditorialsEnabled && (
              <>
                <StyledLink
                  href="/sneakers"
                  onClick={(e) => headerNavigationTrackEvent(e)}
                  onFocus={() => setNavTheme('sneakers')}
                >
                  <HoverIntent onEnter={() => setNavTheme('sneakers')}>
                    <CustomNavLink
                      $isEditorialsEnabled={isEditorialsEnabled}
                      data-qa="NavigationSneakersLink"
                      tabIndex={-1}
                    >
                      {t(`${TP}.sneakers`, 'Sneakers')}
                    </CustomNavLink>
                  </HoverIntent>
                </StyledLink>
                <StyledLink href="/apparel" onClick={(e) => headerNavigationTrackEvent(e)}>
                  <CustomNavLink
                    $isEditorialsEnabled={isEditorialsEnabled}
                    data-qa="NavigationApparelLink"
                    disabled={asPath === '/apparel'}
                    tabIndex={-1}
                  >
                    {t(`${TP}.fcApparel`, 'FC Apparel')}
                  </CustomNavLink>
                </StyledLink>
              </>
            )}
            {!isEditorialsEnabled && displayFlightClubFridays && (
              <StyledLink
                href="https://www.youtube.com/playlist?list=PL9jt6WcuokxZA9n-fCgwLJXZ3UFU6T6WX"
                onClick={(e) => headerNavigationTrackEvent(e)}
                target="_blank"
              >
                <CustomNavLink
                  $isEditorialsEnabled={isEditorialsEnabled}
                  data-qa="flightclub_fridays_youtube"
                  tabIndex={-1}
                >
                  {t(`${TP}.fcFridays`, 'FC Fridays')}
                </CustomNavLink>
              </StyledLink>
            )}
            {isEditorialsEnabled && displayFlightClubFridays && (
              <StyledLink
                href="/editorial/fc-fridays"
                onClick={(e) => headerNavigationTrackEvent(e)}
              >
                <CustomNavLink
                  $isEditorialsEnabled={isEditorialsEnabled}
                  data-qa="flightclub_fridays_editorial"
                  tabIndex={-1}
                >
                  {t(`${TP}.fcFridays`, 'FC Fridays')}
                </CustomNavLink>
              </StyledLink>
            )}
            <StyledLink href="/storelocator" onClick={(e) => headerNavigationTrackEvent(e)}>
              <CustomNavLink
                $isEditorialsEnabled={isEditorialsEnabled}
                data-qa="NavigationStoresLink"
                disabled={asPath === '/storelocator'}
                tabIndex={-1}
              >
                {t(`${TP}.stores`, 'Stores')}
              </CustomNavLink>
            </StyledLink>
            {/* Needs !isMobile check to prevent double rendering of the component */}
            {isFavoritesEnabled && !isMobile && <FavoritesNavLink id="desktop" />}
            <AccountMenu navTheme={navTheme} closeMenu={closeNav} />
          </DesktopButtons>
          <MobileButtons>
            {navTheme !== 'mobile' && (
              <MobileOpen
                $isEditorialsEnabled={isEditorialsEnabled}
                data-qa="MobileNavigationMenu"
                onClick={(e) => {
                  setNavTheme('mobile')
                  headerNavigationTrackEvent(e)
                }}
              >
                {t(`${TP}.menu`, 'Menu')}
              </MobileOpen>
            )}
            {navTheme === 'mobile' && (
              <MobileClose
                $isEditorialsEnabled={isEditorialsEnabled}
                data-qa="MobileNavigationMenuCloseBtn"
                onClick={(e) => {
                  closeNav()
                  headerNavigationTrackEvent(e)
                }}
              >
                {t(`${TP}.close`, 'Close')}
              </MobileClose>
            )}
          </MobileButtons>
        </LinkContainer>
      </NavContainer>
      {!isConsumerSearchEnabled ? (
        <SearchMenu
          isOpen={navTheme === 'search'}
          onRequestClose={closeNav}
          searchText={debouncedSearch}
        />
      ) : (
        <ConsumerSearchSearchMenu
          isOpen={navTheme === 'search'}
          onRequestClose={closeNav}
          searchText={debouncedSearch}
          hits={[]}
        />
      )}
      <SneakersMenu
        isOpen={navTheme === 'sneakers'}
        closeMenu={closeNav}
        setSneakersCancellableHoverTimeout={setSneakersCancellableHoverTimeout}
      />
      <MobileMenu isOpen={navTheme === 'mobile'} closeMenu={closeNav} />
    </React.Fragment>
  )
}

const Navigation = () => {
  const [navTheme, setNavTheme] = useState<NavThemeNames>('overlayed')

  const handleSetNavTheme = useCallback((newTheme) => {
    setNavTheme(newTheme)
  }, [])

  const isConsumerSearchEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_CONSUMER_SEARCH)

  if (isConsumerSearchEnabled) {
    return (
      <ThemeProvider theme={NAVIGATION_THEMES[navTheme]}>
        <ScrollContainer>
          <RawNavigation navTheme={navTheme} setNavTheme={handleSetNavTheme} />
        </ScrollContainer>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={NAVIGATION_THEMES[navTheme]}>
      <ScrollContainer>
        <InstantSearch searchClient={AlgoliaSearchClient} indexName={AlgoliaIndex.Relevance}>
          <Configure hitsPerPage={10} distinct />
          <RawNavigation navTheme={navTheme} setNavTheme={handleSetNavTheme} />
        </InstantSearch>
      </ScrollContainer>
    </ThemeProvider>
  )
}

const MobileClose = styled(BodyText)<{ $isEditorialsEnabled: boolean }>`
  cursor: pointer;
  ${({ $isEditorialsEnabled }) =>
    $isEditorialsEnabled
      ? css`
          align-items: center;
          display: flex;
          font-family: 'Helvetica Neue';
          font-size: 12px;
          font-weight: 500;
          height: 40px;
          justify-content: flex-end;
          line-height: 120%;
          text-transform: uppercase;
        `
      : css`
          padding: 15px 0px;
        `};
`

const MobileOpen = styled(NavLink)<{ $isEditorialsEnabled: boolean }>`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  padding: 0px;

  ${({ $isEditorialsEnabled }) =>
    $isEditorialsEnabled &&
    css`
      font-family: 'Helvetica Neue';
      font-size: 12px;
      font-weight: 500;
      line-height: 120%;
      text-transform: uppercase;
    `};
`

const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
`

export const NavContainer = styled.nav`
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColor};
  border-bottom: 2px solid ${(props) => props.theme.borderColor};
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 24px;
  position: relative;
  transition: background-color 200ms ease-out, border-color 200ms ease-out;
  width: 100%;
  z-index: 999;

  ${media.medium`
    padding: 16px 40px;
  `};
`

const LeftContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: flex-start;
  flex-grow: 1;
  flex-basis: 0;
`

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  text-align: center;
  opacity: 1;
  transition: opacity 200ms ease-in 300ms;
  ${(props) =>
    props.theme.hideLogo &&
    css`
      pointer-events: none;
      transition: opacity 200ms ease-out;
      opacity: 0;

      @media (max-width: ${sizes.medium / 16}em) {
        margin-left: 24px;
      }
    `}
`

export const FlightClubLogo = styled(FlightClubLogoSVG)`
  nav & {
    cursor: pointer;
    height: 44px;
    padding: 10px;
    width: 147px;
  }
`

const LinkContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  opacity: 1;
  text-align: right;
  transition: opacity 200ms ease-in 300ms;
  ${(props) =>
    props.theme.hideLinks &&
    css`
      pointer-events: none;
      transition: opacity 200ms ease-out;
      opacity: 0;
    `}
`

const MobileButtons = styled.div`
  display: none;
  ${mediaQueries.smallNavigation} {
    display: block;
  }
`

const DesktopButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 32px;
  justify-content: flex-end;
  ${mediaQueries.smallNavigation} {
    display: none;
  }
`
const CustomNavLink = styled(NavLink)<{ $isEditorialsEnabled: boolean }>`
  padding: 0px;

  ${({ $isEditorialsEnabled }) =>
    $isEditorialsEnabled &&
    css`
      align-items: center;
      display: flex;
      font-family: 'Helvetica Neue';
      font-size: 12px;
      font-weight: 500;
      height: 40px;
      line-height: 120%;
      text-transform: uppercase;
    `};
`
const StyledLink = styled(Link)<{ $addTransition?: boolean }>`
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  ${({ $addTransition }) =>
    $addTransition &&
    css`
      opacity: 1;
      transition: opacity 200ms ease-in 300ms;
      ${(props) =>
        props.theme.hideLinks &&
        css`
          pointer-events: none;
          transition: opacity 200ms ease-out;
          opacity: 0;
        `}
    `}
`

export default Navigation
