import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import Cookies from 'js-cookie'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { RefObject, createRef, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { Provider } from 'react-redux'
import { Context as ResponsiveContext } from 'react-responsive'
import { v4 as uuidv4 } from 'uuid'
import { setLocale as setYupLocale } from 'yup'

import Bugsnag from '@bugsnag/js'
import Banner from 'banner/Banner'
import { DefaultError } from 'errors/components/DefaultError'
import { GenericErrorBoundary } from 'errors/components/GenericErrorBoundary'
import ExperimentProvider from 'experiments/providers/ExperimentProvider'
import FeatureFlagContextProvider from 'featureFlags/providers/FeatureFlagProvider'
import { HyperLinks } from 'head'
import TrackingScripts from 'head/components/TrackingScripts'
import { LANGUAGE_COOKIE_NAME } from 'localization'
import { getReferrerInfo, getUtmQueryParams } from 'mParticle/mParticleService'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { AppProps } from 'next/app'
import 'normalize.css'
import withApolloClient from 'shared/apollo/withApolloClient'
import ShoppingRegionContextProvider from 'shared/contexts/ShoppingRegionContextProvider'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { STORAGE } from 'shared/enums/SitePreferences'
import withLocalization from 'shared/hocs/withLocalization'
import withTracking from 'shared/hocs/withTracking'
import PageLayout from 'shared/layouts/PageLayout'
import { startBugsnag } from 'shared/lib/bugsnag'
import 'shared/polyfills.js'
import 'shared/styles/global.css'
import { generateRandomInt } from 'shared/utils/generateRandomInt'
import { GetServerPageProps } from 'shared/utils/getServerSideProps'
import { requestService } from 'shared/utils/requestService'
import { setupStore } from 'store/store'

const CHECKOUT_COOKIE = 'checkoutCohort'

type FCAppProps = {
  appContainerRef: RefObject<HTMLDivElement>
  apolloClient: ApolloClient<NormalizedCacheObject>
  avantSiteID: string
  isDesktop: boolean
}

const store = setupStore({})

if (!Bugsnag.isStarted()) {
  startBugsnag()
}

const ErrorBoundary = Bugsnag.isStarted()
  ? Bugsnag.getPlugin('react')!.createErrorBoundary(React)
  : GenericErrorBoundary

const FlightClubApp = ({
  apolloClient,
  avantSiteID,
  Component,
  pageProps,
}: AppProps<GetServerPageProps> & FCAppProps) => {
  const {
    availableCountries,
    availableCurrencies,
    canonicalUrl,
    cmsBanner,
    currentPath,
    isAuthenticated,
    featureFlags,
    footerData,
    locale,
    shoppingRegionCountry,
    initWidth,
  } = pageProps
  const appContainerRef = createRef<HTMLDivElement>()
  const [clientDedupID] = useState(uuidv4())
  const [hasLoggedUtmEvent, setHasLoggedUtmEvent] = useState(false)
  const [width, setWidth] = useState(initWidth)
  const handleResize = () => setWidth(window.innerWidth)

  /**
   * Check if essential middlewares have run:
   * - csrfProtection? -> checks for CSRF token which enables POST/PUT requests
   * - regionPreferenceMiddleware? -> checks for related cookies
   *
   * If not this means the client has loaded a cached page and we need to make a request
   * to the server to get the necessary cookies.
   */
  useEffect(() => {
    const startSession = async () => {
      if (
        !Cookies.get('_csrf') ||
        !Cookies.get(STORAGE.CURRENCY) ||
        !Cookies.get(STORAGE.CURRENCYCODE)
      ) {
        await requestService({ url: '/session', withCredentials: true })
      }
    }
    startSession()
  }, [])

  // log utm event
  useEffect(() => {
    if (!hasLoggedUtmEvent) {
      const utmQueryParams = getUtmQueryParams()
      sendTrackingEvent('CAMPAIGN_SESSION_START', {
        ...getReferrerInfo(),
        ...utmQueryParams,
        client_dedup_id: clientDedupID,
      })
      setHasLoggedUtmEvent(true)
    }
  }, [hasLoggedUtmEvent])

  // set locale in cookie
  useEffect(() => {
    Cookies.set(LANGUAGE_COOKIE_NAME, locale, { path: '/' })
  }, [locale])

  // set yup locale
  useEffect(() => {
    setYupLocale({
      mixed: {
        required: '${path} is a required field.',
      },
    })

    if (appContainerRef.current) {
      ReactModal.setAppElement(appContainerRef.current)
    }
  }, [])

  useEffect(() => {
    if (!Cookies.get(CHECKOUT_COOKIE)) {
      Cookies.set(CHECKOUT_COOKIE, `${generateRandomInt(1, 100)}`)
    }
  }, [])

  // set window width on resize and on mount
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize) // cleanup
    }
  }, [])

  const { pathname } = useRouter()
  // FIXME: temp logic for now
  const isCheckout = pathname === '/checkout' || pathname === '/checkoutSuccess'
  const isEditorialOrArticlePage =
    pathname?.includes('/editorial') || pathname?.includes('/article')
  const areHreflangEnabled = featureFlags?.[FeatureFlag.TEMP_WEB_FC_HREFLANG] ?? false
  const baseUrl = 'https://www.flightclub.com'
  const path = (() => {
    if (currentPath === '/') {
      return ''
    }
    if (currentPath?.includes('?')) {
      return currentPath.split('?')[0]
    }
    return currentPath
  })()
  const enUSUrl = `${baseUrl}${path}`
  const jaJPUrl = `${baseUrl}/ja-jp${path}`
  const enJPUrl = `${baseUrl}/en-jp${path}`

  return (
    <ErrorBoundary
      FallbackComponent={DefaultError}
      onError={(e) => {
        Bugsnag.leaveBreadcrumb('BugsnagProviderErrorBoundary', {
          message: e.errors[0]?.errorMessage,
          fallback: 'DefaultError',
        })
      }}
    >
      <div ref={appContainerRef}>
        <Head>
          <title>Sneakers. Here. | Flight Club</title>
          {areHreflangEnabled && (
            <>
              <link rel="alternate" hrefLang="x-default" href={enUSUrl} />
              <link rel="alternate" hrefLang="en-us" href={enUSUrl} />
              {!isEditorialOrArticlePage && (
                <link rel="alternate" hrefLang="ja-jp" href={jaJPUrl} />
              )}
              {!isEditorialOrArticlePage && (
                <link rel="alternate" hrefLang="en-jp" href={enJPUrl} />
              )}
            </>
          )}
        </Head>
        <ApolloProvider client={apolloClient}>
          <Provider store={store}>
            <ResponsiveContext.Provider value={{ width }}>
              <FeatureFlagContextProvider featureFlags={featureFlags}>
                <TrackingScripts avantSiteID={avantSiteID} />
                <ShoppingRegionContextProvider
                  serverValues={shoppingRegionCountry}
                  availableCountries={availableCountries}
                  availableCurrencies={availableCurrencies}
                  clientSideLocale={locale}
                >
                  <ExperimentProvider>
                    <HyperLinks canonicalUrl={canonicalUrl} currentPath={currentPath} />
                    {!isCheckout && <Banner content={cmsBanner} />}
                    <PageLayout isAuthenticated={isAuthenticated} footerData={footerData}>
                      <Component {...pageProps} />
                    </PageLayout>
                  </ExperimentProvider>
                </ShoppingRegionContextProvider>
              </FeatureFlagContextProvider>
            </ResponsiveContext.Provider>
          </Provider>
        </ApolloProvider>
      </div>
    </ErrorBoundary>
  )
}

// prettier-ignore
export default 
  withApolloClient(
    withTracking(
      withLocalization(FlightClubApp)
    )
  )
