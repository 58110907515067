import { Dispatch, RefObject, SetStateAction, useEffect } from 'react'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { connectSearchBox } from 'react-instantsearch-dom'
import styled, { css } from 'styled-components'

import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { colors, mediaQueries } from 'shared/theme'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { SearchIcon } from 'shared/icons/SearchIcon'

const TP = 'layout.navigation.search.searchInput'

export interface SearchInputProps extends SearchBoxProvided {
  debouncedSearch: string
  isSearchMenuOpen: boolean
  openSearch: () => void
  searchInputEl: RefObject<HTMLInputElement>
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
}

export const ConsumerSearchSearchInput = ({
  isSearchMenuOpen,
  openSearch,
  searchInputEl,
  searchText,
  setSearchText,
}: {
  isSearchMenuOpen: boolean
  openSearch: () => void
  searchInputEl: RefObject<HTMLInputElement>
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
}) => {
  const isEditorialsEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_EDITORIALS)
  return (
    <InputContainer $isEditorialsEnabled={isEditorialsEnabled}>
      <StyledSearchIcon $isEditorialsEnabled={isEditorialsEnabled} />
      <Input
        $isEditorialsEnabled={isEditorialsEnabled}
        $isSearchMenuOpen={isSearchMenuOpen}
        aria-label={t(`${TP}.searchInput`, 'Search input')}
        autoComplete="off"
        data-qa="NavigationSearchInput"
        id="NavigationSearchInput"
        onChange={({ target }) => setSearchText(target.value)}
        onFocus={(e) => {
          // BUY1-304 OneTrust accept button bubbles the click event upward
          // so we need to check if the focus target here is actually this input
          if (e?.currentTarget === e?.target) {
            openSearch()
            sendTrackingEvent('SEARCH_BAR_TAP', {
              page: window?.location?.href,
              service_name: 'consumer_search',
            })
          }
        }}
        placeholder={t(`${TP}.search`, 'Search')}
        ref={searchInputEl}
        role="searchbox"
        tabIndex={0}
        type="text"
        value={searchText}
      />
    </InputContainer>
  )
}
const SearchInput = ({
  isSearchMenuOpen,
  debouncedSearch,
  openSearch,
  refine,
  searchInputEl,
  searchText,
  setSearchText,
}: SearchInputProps) => {
  const isEditorialsEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_EDITORIALS)
  const isConsumerSearchEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_CONSUMER_SEARCH)

  useEffect(() => {
    if (!isConsumerSearchEnabled) {
      refine(debouncedSearch)
      if (debouncedSearch) {
        sendTrackingEvent('SEARCH_BAR_TYPE_PAUSE', {
          page: window?.location?.href,
          search_string: debouncedSearch,
          service_name: isConsumerSearchEnabled ? 'consumer_search' : 'algolia',
        })
      }
    }
  }, [isConsumerSearchEnabled, debouncedSearch])

  return (
    <InputContainer $isEditorialsEnabled={isEditorialsEnabled}>
      <StyledSearchIcon $isEditorialsEnabled={isEditorialsEnabled} />
      <Input
        $isEditorialsEnabled={isEditorialsEnabled}
        $isSearchMenuOpen={isSearchMenuOpen}
        aria-label={t(`${TP}.searchInput`, 'Search input')}
        autoComplete="off"
        data-qa="NavigationSearchInput"
        id="NavigationSearchInput"
        onChange={({ target }) => setSearchText(target.value)}
        onFocus={(e) => {
          // BUY1-304 OneTrust accept button bubbles the click event upward
          // so we need to check if the focus target here is actually this input
          if (e?.currentTarget === e?.target) {
            openSearch()
            sendTrackingEvent('SEARCH_BAR_TAP', {
              page: window?.location?.href,
              service_name: isConsumerSearchEnabled ? 'consumer_search' : 'algolia',
            })
          }
        }}
        placeholder={t(`${TP}.search`, 'Search')}
        ref={searchInputEl}
        role="searchbox"
        tabIndex={0}
        type="text"
        value={searchText}
      />
    </InputContainer>
  )
}

const InputContainer = styled.span<{ $isEditorialsEnabled: boolean }>`
  align-items: center;
  border-radius: 3px;
  display: flex;
  gap: 8px;
  pointer-events: auto;
  transition: border-color 200ms, width 100ms;
  ${mediaQueries.smallNavigation} {
    border-color: ${colors.transparent};
    width: ${(props) => (props.theme.menuOpen ? '100%' : '90px')};
  }
  ${({ $isEditorialsEnabled }) =>
    $isEditorialsEnabled
      ? css`
          width: ${(props) => (props.theme.menuOpen ? '100%' : '70px')};
        `
      : css`
          border: 2px solid ${(props) => props.theme.searchBorderColor};
          width: ${(props) => (props.theme.menuOpen ? '100%' : '240px')};
        `}

  ${(props) =>
    props.theme.menuOpen &&
    css`
      transition: border-color 200ms, width 100ms 200ms;
    `}
`

const StyledSearchIcon = styled(SearchIcon)<{ $isEditorialsEnabled: boolean }>`
  fill: ${(props) => props.theme.textColor};
  ${mediaQueries.smallNavigation} {
    display: none;
  }

  ${({ $isEditorialsEnabled }) =>
    !$isEditorialsEnabled &&
    css`
      margin-left: 8px !important;
      min-height: 14px !important;
      min-width: 14px !important;
      height: 14px !important;
      width: 14px !important;
    `}
`

/* stylelint-disable */
const Input = styled.input<{ $isEditorialsEnabled: boolean; $isSearchMenuOpen: boolean }>`
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.textColor};

  outline: 0;
  width: 100%;

  ${({ $isEditorialsEnabled, $isSearchMenuOpen }) =>
    $isEditorialsEnabled
      ? css`
          font-family: 'Helvetica Neue';
          font-size: 12px;
          font-weight: 500;
          line-height: 40px;
          ${$isSearchMenuOpen &&
          css`
            font-size: 16px;
            margin-top: -2px;
          `}
          ::placeholder {
            font-size: 12px;
            text-transform: uppercase;
          }
        `
      : css`
          font-size: 16px;
          font-weight: 500;
          line-height: 32px;
        `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${(props) => props.theme.backgroundColor};
    -webkit-box-shadow: 0 0 0px 1000px ${(props) => props.theme.backgroundColor} inset;
    background-color: ${(props) => props.theme.backgroundColor} !important;
    background-clip: content-box !important;
  }
  &::placeholder {
    color: ${(props) => props.theme.textColor};
    opacity: 1;
  }

  :focus::placeholder {
    color: ${colors.mediumGray};
  }

  ${mediaQueries.smallNavigation} {
    padding-left: 0;
  }
`
/* stylelint-enable */

export default connectSearchBox(SearchInput)
