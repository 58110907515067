import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { NavThemeNames } from 'layout/navigation/Navigation'
import { getLocalePath, t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { colors, media } from 'shared/lib'
import NavLink from '../components/NavLink'
import SearchInput, { ConsumerSearchSearchInput } from './SearchInput'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import { useLogExperimentWithGroup } from 'experiments/hooks/useLogExperimentWithGroup'

const TP = 'layout.navigation.search.search'

interface SearchProps {
  debouncedSearch: string
  setNavTheme: Dispatch<SetStateAction<NavThemeNames>>
  navTheme: NavThemeNames
  getUnopenedNavTheme: () => NavThemeNames
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
  sneakersCancellableHoverTimeout?: number
}

const Search = ({
  debouncedSearch,
  searchText,
  setNavTheme,
  navTheme,
  setSearchText,
  getUnopenedNavTheme,
  sneakersCancellableHoverTimeout,
}: SearchProps) => {
  const searchInputEl = useRef<HTMLInputElement>(null)
  const router = useRouter()

  const isEditorialsEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_EDITORIALS)
  const isConsumerSearchEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_CONSUMER_SEARCH)
  const consumerSearchExperiment: EXPERIMENT_NAME = 'web_fc_consumer_search'

  const groupName = isConsumerSearchEnabled ? 'consumerSearch' : 'control'

  // CONSUMER SEARCH EXPERIMENT
  const { assignGroup } = useLogExperimentWithGroup(consumerSearchExperiment, groupName)

  const handleKeyPress = useCallback(
    ({ keyCode }) => {
      // ESC
      if (keyCode === 27 && navTheme === 'search') {
        closeSearch()
      }
      // ENTER
      if (keyCode === 13 && navTheme === 'search') {
        // would be ideal to route client side, but SearchPage + Grid
        // are too buggy to allow it at the moment. Needs updating!
        sendTrackingEvent('SEARCH_BAR_SEARCH_TAP', {
          page: window?.location?.href,
          search_string: searchText,
          service_name: isConsumerSearchEnabled ? 'consumer_search' : 'algolia',
        })
        window.location.href = getLocalePath(
          `/catalogsearch/result?query=${searchText}`,
          router.locale,
        )
      }
    },
    [searchText, navTheme],
  )

  useEffect(() => {
    if (navTheme !== 'search') {
      closeSearch()
    }
  }, [navTheme])
  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress)
    return () => {
      window.removeEventListener('keyup', handleKeyPress)
    }
  }, [handleKeyPress])

  const resetSearchText = () => {
    setSearchText('')
  }
  useEffect(() => {
    router.events.on('routeChangeComplete', resetSearchText)

    return () => {
      router.events.off('routeChangeComplete', resetSearchText)
    }
  }, [])

  const openSearch = async () => {
    setNavTheme('search')
    // assign group here
    await assignGroup()
  }

  const clearSearch = () => {
    setSearchText('')
    searchInputEl.current?.focus()
  }
  const closeSearch = () => {
    setSearchText('')
    setNavTheme(getUnopenedNavTheme())
    searchInputEl.current?.blur()
  }

  const handleSearchHover = () => {
    if (sneakersCancellableHoverTimeout) {
      window.clearTimeout(sneakersCancellableHoverTimeout)
    }
  }

  return (
    <ExpandableContainer
      $isSearchOpen={navTheme === 'search'}
      $isEditorialsEnabled={isEditorialsEnabled}
      onMouseEnter={handleSearchHover}
    >
      {isConsumerSearchEnabled ? (
        <ConsumerSearchSearchInput
          isSearchMenuOpen={navTheme === 'search'}
          openSearch={openSearch}
          searchInputEl={searchInputEl}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      ) : (
        <SearchInput
          isSearchMenuOpen={navTheme === 'search'}
          debouncedSearch={debouncedSearch}
          openSearch={openSearch}
          searchInputEl={searchInputEl}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      )}
      <SearchButtons $isEditorialsEnabled={isEditorialsEnabled}>
        <ClearBtn
          $isEditorialsEnabled={isEditorialsEnabled}
          as="button"
          data-qa="NavigationSearchClearBtn"
          onClick={clearSearch}
          disabled={!searchText.length}
          tabIndex={0}
        >
          {t(`${TP}.clear`, 'Clear')}
        </ClearBtn>
        {!isEditorialsEnabled && <SearchButtonDivider />}
        <CloseBtn
          $isEditorialsEnabled={isEditorialsEnabled}
          as="button"
          data-qa="NavigationSearchCloseBtn"
          onClick={closeSearch}
          tabIndex={navTheme === 'search' ? 0 : -1}
        >
          {t(`${TP}.close`, 'Close')}
        </CloseBtn>
      </SearchButtons>
    </ExpandableContainer>
  )
}

const ExpandableContainer = styled.div<{ $isEditorialsEnabled: boolean; $isSearchOpen: boolean }>`
  ${({ $isEditorialsEnabled }) =>
    $isEditorialsEnabled
      ? css`
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `
      : css`
          width: 100%;
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: space-between;

          ${media.medium`
            position: absolute;
            left: 40px;
            top: 0px;
          `};
        `}

  ${({ $isSearchOpen }) =>
    $isSearchOpen
      ? css`
          width: calc(100vw - 48px);
          ${media.medium`
            width: calc(100vw - 80px);
          `};
        `
      : css``}
  pointer-events: none;
  opacity: 1;
  transition: opacity 200ms ease-in 300ms;
  ${(props) =>
    props.theme.hideSearch &&
    css`
      pointer-events: none;
      transition: opacity 200ms;
      opacity: 0;
    `}
  ${(props) =>
    props.theme.menuOpen &&
    css`
      pointer-events: auto;
    `}
`

const ClearBtn = styled(NavLink)<{ $isEditorialsEnabled: boolean }>`
  cursor: pointer;
  color: ${colors.FC2_GREY};
  padding: 0px;
  white-space: nowrap;
  &:disabled {
    color: ${colors.FC2_MEDIUM_GREY};
    cursor: auto;
  }
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
  ${({ $isEditorialsEnabled }) =>
    $isEditorialsEnabled &&
    css`
      font-family: 'Helvetica Neue';
      font-size: 12px;
      font-weight: 500;
      line-height: 120%;
      text-transform: uppercase;
    `};
`

const CloseBtn = styled(NavLink)<{ $isEditorialsEnabled: boolean }>`
  cursor: pointer;
  padding: 0px;
  white-space: nowrap;
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
  ${({ $isEditorialsEnabled }) =>
    $isEditorialsEnabled &&
    css`
      font-family: 'Helvetica Neue';
      font-size: 12px;
      font-weight: 500;
      line-height: 120%;
      text-transform: uppercase;
    `};
`

const SearchButtons = styled.div<{ $isEditorialsEnabled: boolean }>`
  display: none;
  pointer-events: none;
  transition: opacity 200ms;
  ${(props) =>
    props.theme.menuOpen &&
    css`
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: ${(props) => (props?.$isEditorialsEnabled ? '24px' : '12px')};
      justify-content: flex-end;
      opacity: 1;
      pointer-events: auto;
      transition: opacity 200ms 200ms;

      ${media.medium`
        gap: ${(props) => (props?.$isEditorialsEnabled ? '32px' : '12px')};
      `};
    `}
`

const SearchButtonDivider = styled.div`
  height: 72px;
  width: 2px;
  background-color: ${(props) => props.theme.borderColor};
`

export default Search
