import Link from 'next/link'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { useBreadcrumbsQuery } from 'api/breadcrumbsApi'
import ExpandedMenuModal from 'layout/navigation/components/ExpandedMenuModal'
import { fadeInStyles } from 'layout/navigation/components/FadeInStyles'
import HoverIntent from 'layout/navigation/components/HoverIntent'
import { MenuLink, UnderlinedMenuLink } from 'layout/navigation/components/MenuLink'
import getNavLinkHref from 'layout/navigation/components/getNavLink'
import { t } from 'localization'
import { headerNavigationTrackEvent } from 'mParticle/headerNavigationTrackEvent'
import { defaultNavigationLinks } from 'shared/data/defaultBreadcrumbs'
import { Breadcrumbs } from 'shared/enums/Breadcrumbs'
import { sizes } from 'shared/lib/media'
import colors from 'shared/theme/colors'
import { Breadcrumb } from 'shared/types/Breadcrumb'

const TP = 'layout.navigation.SneakersMenu'

interface LinksMenuProps {
  isOpen: boolean
  closeMenu: () => void
  setSneakersCancellableHoverTimeout: (timeout: number) => void
}

interface NavLinkItemsProps extends React.ReactElement<HTMLLIElement> {
  isSelected?: boolean
  onClick?: () => void
}

export const SneakersMenu = ({
  isOpen,
  closeMenu,
  setSneakersCancellableHoverTimeout,
}: LinksMenuProps) => {
  const [navigationLinks, setNavigationLinks] = useState<Breadcrumb>(defaultNavigationLinks)
  const [levelOneSelection, setLevelOneSelection] = useState<Breadcrumb | undefined>()
  const [levelOneHover, setLevelOneHover] = useState<Breadcrumb | undefined>()

  const { data, isSuccess } = useBreadcrumbsQuery(Breadcrumbs.NavigationLinks)

  const levelOneBreadcrumb = levelOneSelection || levelOneHover

  const handleRequestClose = () => {
    setLevelOneSelection(undefined)
    closeMenu()
  }

  useEffect(() => {
    if (isSuccess) {
      setNavigationLinks(data)
    }
  }, [isSuccess])

  useEffect(() => {
    setLevelOneSelection(undefined)
    setLevelOneHover(undefined)
  }, [isOpen])

  return (
    <ExpandedMenuModal
      isOpen={isOpen}
      contentLabel={t(`${TP}.linksMenu`, 'Links Menu')}
      onRequestClose={handleRequestClose}
      qaAttr="navigation-destop-links-menu"
    >
      <HoverIntent
        onExit={handleRequestClose}
        cancellableTimeoutCallback={setSneakersCancellableHoverTimeout}
      >
        <NavigationMenuContainer>
          <StyledLevelOneLinksContainer>
            <StyledLevelOneLinkList>
              {navigationLinks?.children?.map((navItem) =>
                navItem.children ? (
                  <EasyNavEnabledNavLinkItems
                    onClick={() => setLevelOneSelection(navItem)}
                    onMouseEnter={() => setLevelOneHover(navItem)}
                    isSelected={levelOneSelection?.slug === navItem.slug}
                    key={navItem.slug}
                  >
                    {navItem.name}
                  </EasyNavEnabledNavLinkItems>
                ) : (
                  <NavLinkItems
                    onClick={() => setLevelOneSelection(navItem)}
                    onMouseEnter={() => setLevelOneHover(navItem)}
                    isSelected={levelOneSelection?.slug === navItem.slug}
                    key={navItem.slug}
                  >
                    <Link href={getNavLinkHref(navItem)} passHref legacyBehavior>
                      <MenuLink onClick={(e) => headerNavigationTrackEvent(e)}>
                        {navItem.name}
                      </MenuLink>
                    </Link>
                  </NavLinkItems>
                ),
              )}
            </StyledLevelOneLinkList>
          </StyledLevelOneLinksContainer>
          {levelOneBreadcrumb && levelOneBreadcrumb.children && (
            <StyledLevelTwoLinksContainer>
              <Link href={getNavLinkHref(levelOneBreadcrumb)} passHref legacyBehavior>
                <UnderlinedMenuLink onClick={(e) => headerNavigationTrackEvent(e)}>
                  {levelOneBreadcrumb.name}
                </UnderlinedMenuLink>
              </Link>
              <StyledLevelTwoLinkList>
                {navigationLinks?.children
                  ?.find((navItem) => navItem.slug === levelOneBreadcrumb?.slug)
                  ?.children?.map((navItem) => (
                    <NavLinkItems key={navItem.slug}>
                      <Link href={getNavLinkHref(navItem)} passHref legacyBehavior>
                        <MenuLink onClick={(e) => headerNavigationTrackEvent(e)}>
                          {navItem.name}
                        </MenuLink>
                      </Link>
                    </NavLinkItems>
                  ))}
              </StyledLevelTwoLinkList>
            </StyledLevelTwoLinksContainer>
          )}
        </NavigationMenuContainer>
      </HoverIntent>
    </ExpandedMenuModal>
  )
}

const NavigationMenuContainer = styled.div`
  display: flex;
  @media (max-width: ${sizes.large}) {
    height: 70vh;
  }
`

const StyledLevelOneLinksContainer = styled.div`
  padding-top: 40px;
  padding-left: 40px;
  flex-basis: 345px;
`

const StyledLevelTwoLinksContainer = styled.div`
  ${fadeInStyles}
  border-left: 2px solid ${(props) => props.theme.borderColor};
  max-height: 580px;
  flex: 1 1 auto;
  padding: 40px 0 40px 40px;
`

const navLinkStyles = css`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const StyledLevelOneLinkList = styled.ul`
  ${navLinkStyles}
`

const StyledLevelTwoLinkList = styled.ul`
  ${navLinkStyles}
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: calc(100% - 40px);
`

const NavLinkItems = styled.li<Partial<NavLinkItemsProps>>`
  color: ${(props) => (props.isSelected ? colors.darkGray : props.theme.textColor)};
  font-size: 16px;
  line-height: 20px;
  text-decoration: ${(props) => (props.isSelected ? 'underline' : 'none')};
  padding-bottom: 20px;
  cursor: pointer;
  &:hover {
    color: ${colors.darkGray};
  }
`

const EasyNavEnabledNavLinkItems = styled(NavLinkItems)`
  &:hover {
    :before {
      content: '';
      position: relative;
      top: -70px;
      width: 75px;
      height: 75px;
      float: right;
    }
    :after {
      content: '';
      position: relative;
      top: 10px;
      width: 125px;
      height: 125px;
      float: right;
      right: -75px;
    }
  }
`
