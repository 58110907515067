import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { usersApi } from 'api/usersApi'
import { User } from 'shared/types/User'
import { RootState } from 'store/store'

type AuthState = {
  isAuthenticated?: boolean
  user: User | null
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      const user = action.payload
      state.isAuthenticated = true
      state.user = user
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.createGuest.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        const user = { ...action.payload, isGuest: true, name: 'Guest User' }
        state.isAuthenticated = true
        state.user = user
      },
    )
    builder.addMatcher(
      usersApi.endpoints.editUser.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        const user = action.payload
        state.isAuthenticated = true
        state.user = user
      },
    )
    builder.addMatcher(
      usersApi.endpoints.login.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        const user = action.payload
        state.isAuthenticated = true
        state.user = user
      },
    )
    builder.addMatcher(usersApi.endpoints.logout.matchFulfilled, (state) => {
      state.isAuthenticated = false
      state.user = null
    })
    builder.addMatcher(
      usersApi.endpoints.registerUser.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        const user = action.payload
        state.isAuthenticated = true
        state.user = user
      },
    )
    builder.addMatcher(
      usersApi.endpoints.upgradeGuest.matchFulfilled,
      (state, action: PayloadAction<User>) => {
        const user = action.payload
        state.isAuthenticated = true
        state.user = user
      },
    )
  },
})

const { actions: authActions, reducer: authReducer } = authSlice
export const { setUser } = authActions
export { authReducer }

// Auth/User Selectors
export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectUserIsAuthenticated = (state: RootState) => state.auth.isAuthenticated
