import styled from 'styled-components'
import React from 'react'

export const SearchIcon = ({ className }: { className?: string }) => (
  <CustomSvg
    className={className}
    data-qa="SearchIcon"
    viewBox="0 0 10 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon_Search">
      <path
        id="icon_Search_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.17523 0.5C3.10289 0.5 2.04381 0.904583 1.22229 1.72697C-0.40743 3.35843 -0.40743 6.00768 1.22229 7.63913C2.04381 8.46152 3.10289 8.86611 4.17523 8.86611C5.03148 8.86611 5.88901 8.60856 6.61093 8.07995L9.05557 10.5L10 9.55457L7.5687 7.12068C8.75285 5.49289 8.60582 3.19269 7.12795 1.72676C6.30646 0.904512 5.24748 0.5 4.17523 0.5ZM2.18097 2.67265C2.71205 2.14114 3.42405 1.84301 4.18875 1.84301C4.95272 1.84301 5.65213 2.14067 6.19783 2.67394C6.74053 3.20428 7.02577 3.91611 7.02577 4.68305C7.02577 5.44784 6.72843 6.14799 6.19573 6.69427C5.66615 7.23734 4.94166 7.5231 4.18875 7.5231C3.42503 7.5231 2.72584 7.22564 2.18022 6.69271C1.08026 5.59102 1.07961 3.78843 2.18097 2.67265Z"
      />
    </g>
  </CustomSvg>
)

const CustomSvg = styled.svg`
  height: 10px;
  width: 10px;
  min-height: 10px;
  min-width: 10px;
`
